// "use client";

// import React, { useRef, useEffect, useState, useCallback } from "react";
// import BlockLoader from "../Common/BlockLoader";

// export default function DownloadableImage({ image, title, priority = false }) {
//   const canvasRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const isMounted = useRef(false);

//   const drawRoundedRect = useCallback(
//     (ctx, x, y, width, height, radius, fillStyle) => {
//       ctx.beginPath();
//       ctx.moveTo(x + radius, y);
//       ctx.arcTo(x + width, y, x + width, y + height, radius);
//       ctx.arcTo(x + width, y + height, x, y + height, radius);
//       ctx.arcTo(x, y + height, x, y, radius);
//       ctx.arcTo(x, y, x + width, y, radius);
//       ctx.closePath();
//       ctx.fillStyle = fillStyle;
//       ctx.fill();
//     },
//     []
//   );

//   const wrapText = useCallback((ctx, text, maxWidth) => {
//     const words = text.split(" ");
//     const lines = [];
//     let currentLine = words[0];

//     for (let i = 1; i < words.length; i++) {
//       const testLine = `${currentLine} ${words[i]}`;
//       if (ctx.measureText(testLine).width > maxWidth) {
//         lines.push(currentLine);
//         currentLine = words[i];
//       } else {
//         currentLine = testLine;
//       }
//     }
//     lines.push(currentLine);
//     return lines;
//   }, []);

//   useEffect(() => {
//     isMounted.current = true;

//     if (!image || !title) return;

//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     // Set canvas dimensions explicitly
//     canvas.width = 1024;
//     canvas.height = 576;

//     // Clear the canvas
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     // Draw background
//     ctx.fillStyle = "#000"; // Black background
//     ctx.fillRect(0, 0, canvas.width, canvas.height);

//     const img = new Image();
//     img.crossOrigin = "anonymous";
//     img.src = image;

//     img.onload = () => {
//       if (!isMounted.current) return;

//       // Calculate scaling
//       const scale = Math.min(
//         canvas.width / img.width,
//         canvas.height / img.height
//       );
//       const scaledWidth = img.width * scale;
//       const scaledHeight = img.height * scale;
//       const offsetX = (canvas.width - scaledWidth) / 2;
//       const offsetY = (canvas.height - scaledHeight) / 2;

//       // Draw the image
//       ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

//       // Draw logo background
//       drawRoundedRect(ctx, 10, 20, 300, 60, 15, "rgba(255, 255, 255, 0.5)");

//       // Draw logo text
//       ctx.font = "40px 'Bilbo Swash Caps'";
//       ctx.fillStyle = "black";
//       ctx.fillText("My Creative Recipes", 30, 60);

//       // Draw title text
//       ctx.font = "600 40px Arial";
//       ctx.fillStyle = "white";
//       ctx.textAlign = "center";

//       const maxWidth = canvas.width - 40;
//       const lines = wrapText(ctx, title, maxWidth);
//       const lineHeight = 48;
//       const textAreaHeight = lines.length * lineHeight + 20;
//       const bottomY = canvas.height - textAreaHeight;

//       // Draw title background
//       ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
//       ctx.fillRect(0, bottomY, canvas.width, textAreaHeight);

//       // Draw title text
//       ctx.fillStyle = "white";
//       lines.forEach((line, i) => {
//         ctx.fillText(line, canvas.width / 2, bottomY + lineHeight * (i + 1));
//       });

//       // Convert canvas to image URL
//       const generatedImageURL = canvas.toDataURL("image/webp");
//       setImageURL(generatedImageURL);
//       setIsLoading(false);
//     };

//     img.onerror = () => {
//       console.error("Image failed to load");
//       setIsLoading(false);
//     };

//     return () => {
//       isMounted.current = false;
//     };
//   }, [image, title, drawRoundedRect, wrapText]);

//   return (
//     <div className="flex flex-col items-center">
//       <canvas
//         ref={canvasRef}
//         style={{ display: "none" }} // Hide canvas without interfering with rendering
//       />
//       {!isLoading && imageURL ? (
//         <img
//           // src={imageURL}
//           src={imageURL}
//           alt={title}
//           width={800}
//           height={450}
//           loading={priority === true ? "eager" : "lazy"}
//           className="w-full rounded-md h-auto  object-cover"
//         />
//       ) : (
//         <BlockLoader />
//       )}
//     </div>
//   );
// }

// "use client";

// import React, { useRef, useEffect, useState, useMemo } from "react";
// import BlockLoader from "../Common/BlockLoader";

// export default function DownloadableImage({ image, title, priority = false }) {
//   const canvasRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const isMounted = useRef(false);

//   // Memoizing rounded rectangle function for better performance
//   const drawRoundedRect = useMemo(
//     () => (ctx, x, y, width, height, radius, fillStyle) => {
//       ctx.beginPath();
//       ctx.moveTo(x + radius, y);
//       ctx.arcTo(x + width, y, x + width, y + height, radius);
//       ctx.arcTo(x + width, y + height, x, y + height, radius);
//       ctx.arcTo(x, y + height, x, y, radius);
//       ctx.arcTo(x, y, x + width, y, radius);
//       ctx.closePath();
//       ctx.fillStyle = fillStyle;
//       ctx.fill();
//     },
//     []
//   );

//   // Memoizing text wrapping function for efficiency
//   const wrapText = useMemo(
//     () => (ctx, text, maxWidth) => {
//       const words = text.split(" ");
//       const lines = [];
//       let currentLine = words[0];

//       for (let i = 1; i < words.length; i++) {
//         const testLine = `${currentLine} ${words[i]}`;
//         if (ctx.measureText(testLine).width > maxWidth) {
//           lines.push(currentLine);
//           currentLine = words[i];
//         } else {
//           currentLine = testLine;
//         }
//       }
//       lines.push(currentLine);
//       return lines;
//     },
//     []
//   );

//   useEffect(() => {
//     isMounted.current = true;
//     if (!image || !title) return;

//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     // Set canvas dimensions explicitly
//     canvas.width = 1024;
//     canvas.height = 576;
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     const img = new Image();
//     img.crossOrigin = "anonymous";
//     img.src = image;

//     img
//       .decode()
//       .then(() => {
//         if (!isMounted.current) return;

//         // Calculate scaling
//         const scale = Math.min(
//           canvas.width / img.width,
//           canvas.height / img.height
//         );
//         const scaledWidth = img.width * scale;
//         const scaledHeight = img.height * scale;
//         const offsetX = (canvas.width - scaledWidth) / 2;
//         const offsetY = (canvas.height - scaledHeight) / 2;

//         // Draw background
//         ctx.fillStyle = "#000";
//         ctx.fillRect(0, 0, canvas.width, canvas.height);

//         // Draw the image
//         ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

//         // Draw logo background
//         drawRoundedRect(ctx, 10, 20, 300, 60, 15, "rgba(255, 255, 255, 0.5)");

//         // Draw logo text
//         ctx.font = "40px 'Bilbo Swash Caps'";
//         ctx.fillStyle = "black";
//         ctx.fillText("My Creative Recipes", 30, 60);

//         // Draw title text
//         ctx.font = "600 40px Arial";
//         ctx.fillStyle = "white";
//         ctx.textAlign = "center";

//         const maxWidth = canvas.width - 40;
//         const lines = wrapText(ctx, title, maxWidth);
//         const lineHeight = 48;
//         const textAreaHeight = lines.length * lineHeight + 20;
//         const bottomY = canvas.height - textAreaHeight;

//         // Draw title background
//         ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
//         ctx.fillRect(0, bottomY, canvas.width, textAreaHeight);

//         // Draw title text
//         ctx.fillStyle = "white";
//         lines.forEach((line, i) => {
//           ctx.fillText(line, canvas.width / 2, bottomY + lineHeight * (i + 1));
//         });

//         // Convert canvas to image URL without redundant state updates
//         setImageURL(canvas.toDataURL("image/webp"));
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         console.error("Image failed to load", err);
//         setIsLoading(false);
//       });

//     return () => {
//       isMounted.current = false;
//     };
//   }, [image, title, drawRoundedRect, wrapText]);

//   return (
//     <div className="flex flex-col items-center">
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       {!isLoading && imageURL ? (
//         <img
//           src={imageURL}
//           alt={title}
//           width={800}
//           height={450}
//           loading={priority === true ? "eager" : "lazy"}
//           className="w-full rounded-md h-auto object-cover"
//         />
//       ) : (
//         <BlockLoader />
//       )}
//     </div>
//   );
// }

// ********************

// "use client";

// import React, { useRef, useEffect, useState, useMemo } from "react";
// import BlockLoader from "../Common/BlockLoader";

// export default function DownloadableImage({ image, title, priority = false }) {
//   const canvasRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [jsEnabled, setJsEnabled] = useState(false);
//   const isMounted = useRef(false);

//   // Check if JavaScript is enabled (client-side only)
//   useEffect(() => {
//     setJsEnabled(true);
//   }, []);

//   // Memoizing rounded rectangle function
//   const drawRoundedRect = useMemo(
//     () => (ctx, x, y, width, height, radius, fillStyle) => {
//       ctx.beginPath();
//       ctx.moveTo(x + radius, y);
//       ctx.arcTo(x + width, y, x + width, y + height, radius);
//       ctx.arcTo(x + width, y + height, x, y + height, radius);
//       ctx.arcTo(x, y + height, x, y, radius);
//       ctx.arcTo(x, y, x + width, y, radius);
//       ctx.closePath();
//       ctx.fillStyle = fillStyle;
//       ctx.fill();
//     },
//     []
//   );

//   // Memoizing text wrapping function
//   const wrapText = useMemo(
//     () => (ctx, text, maxWidth) => {
//       const words = text.split(" ");
//       const lines = [];
//       let currentLine = words[0];

//       for (let i = 1; i < words.length; i++) {
//         const testLine = `${currentLine} ${words[i]}`;
//         if (ctx.measureText(testLine).width > maxWidth) {
//           lines.push(currentLine);
//           currentLine = words[i];
//         } else {
//           currentLine = testLine;
//         }
//       }
//       lines.push(currentLine);
//       return lines;
//     },
//     []
//   );

//   // Main image processing effect
//   useEffect(() => {
//     if (!jsEnabled) return;

//     isMounted.current = true;
//     if (!image || !title) return;

//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");

//     // Set canvas dimensions
//     canvas.width = 1024;
//     canvas.height = 576;
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     const img = new Image();
//     img.crossOrigin = "anonymous";
//     img.src = image;

//     img
//       .decode()
//       .then(() => {
//         if (!isMounted.current) return;

//         // Calculate scaling
//         const scale = Math.min(
//           canvas.width / img.width,
//           canvas.height / img.height
//         );
//         const scaledWidth = img.width * scale;
//         const scaledHeight = img.height * scale;
//         const offsetX = (canvas.width - scaledWidth) / 2;
//         const offsetY = (canvas.height - scaledHeight) / 2;

//         // Draw background
//         ctx.fillStyle = "#000";
//         ctx.fillRect(0, 0, canvas.width, canvas.height);

//         // Draw the image
//         ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

//         // Draw logo background
//         drawRoundedRect(ctx, 10, 20, 300, 60, 15, "rgba(255, 255, 255, 0.5)");

//         // Draw logo text
//         ctx.font = "40px 'Bilbo Swash Caps'";
//         ctx.fillStyle = "black";
//         ctx.fillText("My Creative Recipes", 30, 60);

//         // Draw title text
//         ctx.font = "600 40px Arial";
//         ctx.fillStyle = "white";
//         ctx.textAlign = "center";

//         const maxWidth = canvas.width - 40;
//         const lines = wrapText(ctx, title, maxWidth);
//         const lineHeight = 48;
//         const textAreaHeight = lines.length * lineHeight + 20;
//         const bottomY = canvas.height - textAreaHeight;

//         // Draw title background
//         ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
//         ctx.fillRect(0, bottomY, canvas.width, textAreaHeight);

//         // Draw title text
//         ctx.fillStyle = "white";
//         lines.forEach((line, i) => {
//           ctx.fillText(line, canvas.width / 2, bottomY + lineHeight * (i + 1));
//         });

//         // Convert canvas to image URL
//         setImageURL(canvas.toDataURL("image/webp"));
//         setIsLoading(false);
//       })
//       .catch((err) => {
//         console.error("Image processing failed", err);
//         setIsLoading(false);
//       });

//     return () => {
//       isMounted.current = false;
//     };
//   }, [image, title, drawRoundedRect, wrapText, jsEnabled]);

//   // Fallback for SSR and no-JS environments
//   if (!jsEnabled) {
//     return (
//       <div className="flex flex-col items-center">
//         <img
//           src={image}
//           alt={title}
//           width={800}
//           height={450}
//           loading={priority ? "eager" : "lazy"}
//           className="w-full rounded-md h-auto object-cover"
//         />
//         <div className="mt-2 p-2 bg-gray-100 rounded text-center">
//           <p className="text-sm font-medium">{title}</p>
//         </div>
//       </div>
//     );
//   }

//   // Main JS-enabled render
//   return (
//     <div className="flex flex-col items-center">
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//       {!isLoading && imageURL ? (
//         <img
//           src={imageURL}
//           alt={title}
//           width={800}
//           height={450}
//           loading={priority ? "eager" : "lazy"}
//           className="w-full rounded-md h-auto object-cover"
//         />
//       ) : (
//         <div className="w-full aspect-video flex items-center justify-center">
//           <BlockLoader />
//         </div>
//       )}
//     </div>
//   );
// }

////////////////////////////

// "use client";

// import React, { useRef, useEffect, useState } from "react";
// import BlockLoader from "../Common/BlockLoader";

// // Static helper functions (no re-render recreations)
// const drawRoundedRect = (ctx, x, y, width, height, radius, fillStyle) => {
//   ctx.beginPath();
//   ctx.moveTo(x + radius, y);
//   ctx.arcTo(x + width, y, x + width, y + height, radius);
//   ctx.arcTo(x + width, y + height, x, y + height, radius);
//   ctx.arcTo(x, y + height, x, y, radius);
//   ctx.arcTo(x, y, x + width, y, radius);
//   ctx.closePath();
//   ctx.fillStyle = fillStyle;
//   ctx.fill();
// };

// const wrapText = (ctx, text, maxWidth) => {
//   const words = text.split(" ");
//   const lines = [];
//   let currentLine = words[0];

//   for (let i = 1; i < words.length; i++) {
//     const testLine = `${currentLine} ${words[i]}`;
//     if (ctx.measureText(testLine).width > maxWidth) {
//       lines.push(currentLine);
//       currentLine = words[i];
//     } else {
//       currentLine = testLine;
//     }
//   }
//   lines.push(currentLine);
//   return lines;
// };

// export default function DownloadableImage({ image, title, priority = false }) {
//   const canvasRef = useRef(null);
//   const [imageURL, setImageURL] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   useEffect(() => {
//     if (!image || !title) return;

//     const canvas = canvasRef.current;
//     const ctx = canvas.getContext("2d");
//     let isActive = true;

//     // Fixed dimensions (optimized for sharing)
//     canvas.width = 1024;
//     canvas.height = 576;
//     ctx.clearRect(0, 0, canvas.width, canvas.height);

//     const img = new Image();
//     img.crossOrigin = "anonymous";
//     img.src = image;
//     img.decoding = "async"; // Better for off-main-thread decoding

//     const handleLoad = () => {
//       if (!isActive) return;

//       // Single draw operation (optimized)
//       requestAnimationFrame(() => {
//         // 1. Draw background
//         ctx.fillStyle = "#000";
//         ctx.fillRect(0, 0, canvas.width, canvas.height);

//         // 2. Draw image (centered & scaled)
//         const scale = Math.min(
//           canvas.width / img.width,
//           canvas.height / img.height
//         );
//         const scaledWidth = img.width * scale;
//         const scaledHeight = img.height * scale;
//         const offsetX = (canvas.width - scaledWidth) / 2;
//         const offsetY = (canvas.height - scaledHeight) / 2;
//         ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

//         // 3. Draw logo (batched)
//         drawRoundedRect(ctx, 10, 20, 300, 60, 15, "rgba(255, 255, 255, 0.5)");
//         ctx.font = "40px 'Bilbo Swash Caps'";
//         ctx.fillStyle = "black";
//         ctx.fillText("My Creative Recipes", 30, 60);

//         // 4. Draw title (optimized text measurement)
//         ctx.font = "600 40px Arial";
//         ctx.fillStyle = "white";
//         ctx.textAlign = "center";
//         const maxWidth = canvas.width - 40;
//         const lines = wrapText(ctx, title, maxWidth);
//         const lineHeight = 48;
//         const textAreaHeight = lines.length * lineHeight + 20;
//         const bottomY = canvas.height - textAreaHeight;

//         // Single fill for background
//         ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
//         ctx.fillRect(0, bottomY, canvas.width, textAreaHeight);

//         // Batch text rendering
//         ctx.fillStyle = "white";
//         lines.forEach((line, i) => {
//           ctx.fillText(line, canvas.width / 2, bottomY + lineHeight * (i + 1));
//         });

//         // Convert to WebP (better compression)
//         canvas.toBlob(
//           (blob) => {
//             if (blob && isActive) {
//               setImageURL(URL.createObjectURL(blob));
//               setIsLoading(false);
//             }
//           },
//           "image/webp",
//           0.9 // Quality balance
//         );
//       });
//     };

//     img.addEventListener("load", handleLoad);
//     img.addEventListener("error", () => {
//       if (isActive) setIsLoading(false);
//     });

//     return () => {
//       isActive = false;
//       img.removeEventListener("load", handleLoad);
//       if (imageURL) URL.revokeObjectURL(imageURL); // Prevent memory leaks
//     };
//   }, [image, title]);

//   return (
//     <div className="flex flex-col items-center">
//       <canvas ref={canvasRef} style={{ display: "none" }} aria-hidden="true" />
//       {isLoading ? (
//         <BlockLoader />
//       ) : (
//         <img
//           src={imageURL}
//           alt={title}
//           width={800}
//           height={450}
//           loading={priority ? "eager" : "lazy"}
//           decoding={priority ? "sync" : "async"}
//           className="w-full rounded-md h-auto object-cover"
//           onLoad={() => URL.revokeObjectURL(imageURL)} // Cleanup
//         />
//       )}
//     </div>
//   );
// }

/////////////////above workable code
"use client";

import React, { useRef, useEffect, useState } from "react";

// Static helper functions outside component to prevent recreation
const drawRoundedRect = (ctx, x, y, width, height, radius, fillStyle) => {
  ctx.beginPath();
  ctx.moveTo(x + radius, y);
  ctx.lineTo(x + width - radius, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
  ctx.lineTo(x + width, y + height - radius);
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
  ctx.lineTo(x + radius, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
  ctx.lineTo(x, y + radius);
  ctx.quadraticCurveTo(x, y, x + radius, y);
  ctx.closePath();
  ctx.fillStyle = fillStyle;
  ctx.fill();
};

const wrapText = (ctx, text, maxWidth) => {
  const words = text.split(" ");
  const lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    const testLine = currentLine + " " + words[i];
    const metrics = ctx.measureText(testLine);
    if (metrics.width > maxWidth) {
      lines.push(currentLine);
      currentLine = words[i];
    } else {
      currentLine = testLine;
    }
  }
  lines.push(currentLine);
  return lines;
};

// function BlockLoader() {
//   return (
//     <div className="relative w-full overflow-hidden rounded-md bg-slate-200">
//       <div
//         className="w-full h-0"
//         style={{
//           paddingTop: "56.25%", // 16:9 aspect ratio
//         }}
//       ></div>
//     </div>
//   );
// }

export default function DownloadableImage({ image, title, priority = false }) {
  const canvasRef = useRef(null);
  const [enhancedImage, setEnhancedImage] = useState(null);
  // const [isLoading, setIsLoading] = useState(false); // Start false for SSR/JS-disabled

  useEffect(() => {
    if (!image || !title) return;

    // setIsLoading(true); // Only true when JS executes
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    let active = true;

    // Set canvas dimensions
    canvas.width = 1024;
    canvas.height = 576;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = image;
    img.decoding = "async";

    const handleLoad = () => {
      if (!active) return;

      // Draw background
      ctx.fillStyle = "#000";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw image (centered and scaled)
      const scale = Math.min(
        canvas.width / img.width,
        canvas.height / img.height
      );
      const width = img.width * scale;
      const height = img.height * scale;
      ctx.drawImage(
        img,
        (canvas.width - width) / 2,
        (canvas.height - height) / 2,
        width,
        height
      );

      // Draw branding
      drawRoundedRect(ctx, 10, 20, 300, 60, 15, "rgba(255, 255, 255, 0.5)");
      ctx.font = "40px 'Bilbo Swash Caps'";
      ctx.fillStyle = "black";
      ctx.fillText("My Creative Recipes", 30, 60);

      // Draw title
      ctx.font = "600 40px Arial";
      ctx.fillStyle = "white";
      ctx.textAlign = "center";
      const lines = wrapText(ctx, title, canvas.width - 40);
      const textHeight = lines.length * 48 + 20;
      const textY = canvas.height - textHeight;

      // Title background
      ctx.fillStyle = "rgba(0, 0, 0, 0.7)";
      ctx.fillRect(0, textY, canvas.width, textHeight);

      // Title text
      ctx.fillStyle = "white";
      lines.forEach((line, i) => {
        ctx.fillText(line, canvas.width / 2, textY + 48 * (i + 1));
      });

      // Convert to WebP
      canvas.toBlob(
        (blob) => {
          if (blob && active) {
            setEnhancedImage(URL.createObjectURL(blob));
            // setIsLoading(false);
          }
        },
        "image/webp",
        0.85 // Quality balance
      );
    };

    img.addEventListener("load", handleLoad);
    img.addEventListener("error", () => {
      // if (active) setIsLoading(false);
    });

    return () => {
      active = false;
      img.removeEventListener("load", handleLoad);
      if (enhancedImage) URL.revokeObjectURL(enhancedImage);
    };
  }, [image, title]);

  return (
    <div className="relative">
      {/* Fallback image - always visible first */}
      <img
        src={image}
        alt={title}
        width={800}
        height={450}
        loading={priority ? "eager" : "lazy"}
        decoding="async"
        className={`w-full rounded-md h-auto object-cover transition-opacity duration-300 ${
          enhancedImage ? "hidden" : "block"
        }`}
      />
      {/* ////Loading state - only during JS processing */}
      {/* {isLoading && (
        <img
          src={image}
          alt={title}
          width={800}
          height={450}
          loading={priority ? "eager" : "lazy"}
          decoding="async"
          className={`w-full rounded-md h-auto object-cover transition-opacity duration-300 ${
            enhancedImage ? "hidden" : "block"
          }`}
        />
      )} */}
      {/* Enhanced image - replaces fallback when ready */}
      {enhancedImage && (
        <img
          src={enhancedImage}
          alt={title}
          width={800}
          height={450}
          loading={priority ? "eager" : "lazy"}
          className="w-full rounded-md h-auto object-cover"
        />
      )}
      {/* Hidden canvas */}
      <canvas ref={canvasRef} style={{ display: "none" }} aria-hidden="true" />
    </div>
  );
}
